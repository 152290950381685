<template>
  <v-container fluid>

    <div style="float: right; position: absolute; right: 300px; top:25px;">
      <v-btn @click="showLegend()" style="float: right; position: absolute; right: 45px; top:-3px; z-index: 1;" small outlined dense>{{ $t("homeHeader.status_legend")}}</v-btn>
      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          @click="handleIconClick"
          v-bind="attrs"
          v-on="on"
          style="cursor: pointer; z-index: 1;"
        >
          {{ hideSomeColumn ? "mdi-table-eye" : "mdi-table-eye-off" }}
        </v-icon>
      </template>
      <span>{{
        hideSomeColumn ? "All Columns" : "Hide Some Columns"
      }}</span>
      </v-tooltip>
    </div>


    <tool-bar :toolbar="toolbar"> </tool-bar>

      <v-data-table
        class="data-table-header"
        :headers="productHeaderData"
        :height="tableHeight"
        :items="searchTsuika"
        fixed-header
        :items-per-page="50" 
        :footer-props="footerProps"
        hide-default-header
        @current-items="getFilteredData"
      >
        <template #header="{ props: { headers } }">
          <thead class="v-data-table-header">
            <tr>
              <th
                v-for="header in headers"
                :key="header.text"
                scope="col"
                :class="header.id == 2 ? 'sticky' : header.id == 3 ? 'stickyCode' : 'text'"
                :style="headerWidth(header.id)"
              >
                {{ header.text}}
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="{ items }">
            <tr v-for="(item, key) in items" :key="key" class="highlight">
              <td class="text-center" :style="rowColor(item)">{{ convertDate(item.created_at) }}</td>
              <td class="stickyTd text-center" :style="rowColor(item)">{{ item.order_number }}</td>
              <td class="stickyTdCode text-center" :style="rowColor(item)">{{"   " + item.construction_code + "   " }}</td>
              <td class="text-center" :style="rowColor(item)">{{ getRequester('name', item.created_by) }}</td>
              <td class="text-center" :style="rowColor(item)">{{ getRequester('email', item.created_by) }}</td>
              <td class="text-center" :style="rowColor(item)">{{item.additional_industry_id}}</td>
              <td class="text-center" :style="rowColor(item)">{{item.product_industry_name}}</td>
              <td class="text-center" :style="rowColor(item)" v-show="!hideSomeColumn">{{item.industry_product_name}}</td>
              <td class="text-center" :style="rowColor(item)" v-show="!hideSomeColumn">{{item.quantity}}</td>
              <td class="text-center" :style="rowColor(item)" v-show="!hideSomeColumn">{{item.unit}}</td>
              <td class="text-center" :style="rowColor(item)" v-show="!hideSomeColumn">{{item.delivery_date}}</td>
              <td class="text-center" :style="rowColor(item)" v-show="!hideSomeColumn">{{item.additional_cause}}</td>
              <td class="text-center" :style="rowColor(item)" v-show="!hideSomeColumn">{{item.additional_reason}}</td>
              <template v-if="withSituationPhoto(item.situation_photo) > 0">
                <td class="text-center" :style="rowColor(item)" v-show="!hideSomeColumn">
                  <v-btn small icon @click="getImages(item)">
                    <v-icon color="#1B5E20">mdi-image-multiple</v-icon>
                  </v-btn>
                </td>
              </template>
              <template v-else>
                <td class="text-center" :style="rowColor(item)" v-show="!hideSomeColumn">
                  <v-btn disabled small icon @click="getImages(item)">
                    <v-icon color="#424242">mdi-image-multiple</v-icon>
                  </v-btn>
                </td>
              </template>
              <td class="text-center" :style="rowColor(item)" v-show="!hideSomeColumn">
                  {{ desiredDeliveryAddress.find(res => res.id == item.desired_delivery_address).text }}
              </td>
              <td class="text-center" :style="rowColor(item)" v-show="!hideSomeColumn">{{item.delivery_address == 'N/A' ? '' : item.delivery_address}}</td>
              <td class="text-center" :style="rowColor(item)" v-show="!hideSomeColumn">{{item.recipient}}</td>
              <td class="text-center" :style="rowColor(item)" v-show="!hideSomeColumn">{{item.recipient_tel_number}}</td>
              <td class="text-center" :style="rowColor(item)" v-show="!hideSomeColumn">{{item.recipient_address}}</td>
              <td class="text-center" :style="rowColor(item)" >
                <template v-if="item.user_email == loggedInUser.email">
                  <template v-if="item.additional_cause == 'GC'">
                    <template v-if="item.status == 1">
                      <v-chip
                        v-if="item.imported_status == 3"
                        small
                        class="ma-2"
                        :color="isEmailSend(item)"
                        label
                        style="color: white;"
                        @dblclick="emailSupplier(item)"
                      >
                        ORDERED
                      </v-chip>
                      <v-chip
                        v-else
                        small
                        class="ma-2"
                        color="teal darken-4"
                        label
                        outlined
                        style="color: white;"
                        @dblclick="manualImport(item)"
                      >
                        GC SENT
                      </v-chip>
                    </template>
                    <template v-else-if="item.status == 4">
                      <v-chip
                        small
                        class="ma-2"
                        color="#D32F2F"
                        label
                        outlined
                        style="color: #D32F2F; font-size: 9px !important;"
                      >
                        GC CANCELLED
                      </v-chip>
                    </template>
                  </template>

                  
                  <template v-else-if="!item.status">
                    <v-chip
                      small
                      class="ma-2"
                      color="lime darken-4"
                      outlined
                      label
                    >
                      PENDING
                    </v-chip>
                  </template>
                  <template v-else-if="item.status == 2">
                    <v-chip
                        v-if="item.imported_status == 3"
                        small
                        class="ma-2"
                        :color="isEmailSend(item)"
                        label
                        style="color: white;"
                        @dblclick="emailSupplier(item)"
                      >
                        ORDERED
                    </v-chip>
                    <v-chip
                      v-else
                      small
                      class="ma-2"
                      color="green darken-4"
                      outlined
                      label
                      @dblclick="manualImport(item)"
                    >
                      <!-- HRD SENT -->
                      APPROVED
                    </v-chip>
                  </template>
                  <template v-else>
                    <v-chip
                      small
                      class="ma-2 center"
                      color="red accent-4"
                      outlined
                      label
                    >
                    HRD CANCELLED
                    </v-chip>
                  </template>
                </template>

                
                <template v-else>
                  <template v-if="item.additional_cause == 'GC'">

                    <template v-if="item.status == 1">
                      <v-chip
                        v-if="item.imported_status == 3"
                        small
                        class="ma-2"
                        :color="isEmailSend(item)"
                        label
                        style="color: white;"
                        @dblclick="emailSupplier(item)"
                      >
                        ORDERED
                      </v-chip>
                      <v-chip
                        v-else
                        small
                        class="ma-2"
                        color="teal darken-4"
                        label
                        outlined
                        style="color: white;"
                        @dblclick="manualImport(item)"
                      >
                        GC SENT
                      </v-chip>
                    </template>


                    
                    <template v-if="item.status == 4">
                      <v-chip
                        small
                        class="ma-2"
                        color="red accent-4"
                        label
                        outlined
                        style="color: white;"
                      >
                        GC CANCELLED
                      </v-chip>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="!item.status">
                      <v-chip
                        small
                        class="ma-2"
                        color="red"
                        outlined
                        label
                        @click="checkItemData(item)"
                        :disabled="loggedInUser.account_type == 'GC' ? true : false"
                      >
                        <v-icon class="mr-1" dark small color="red">
                        </v-icon>
                        ASSESS
                      </v-chip>
                    </template>
                    <template v-else-if="item.status == 2">
                      <v-chip
                        v-if="item.imported_status == 3"
                        small
                        class="ma-2"
                        :color="isEmailSend(item)"
                        label
                        style="color: white;"
                        @dblclick="emailSupplier(item)"
                      >
                        ORDERED
                      </v-chip>
                      <v-chip
                        v-else
                        small
                        class="ma-2"
                        color="green darken-4"
                        outlined
                        label
                        @dblclick="manualImport(item)"
                      >
                        <!-- HRD SENT -->
                        APPROVED
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip
                        small
                        class="ma-2"
                        color="red accent-4"
                        outlined
                        label
                      >
                        <!-- CANCELLED -->
                        HRD CANCELLED
                      </v-chip>
                    </template>
                  </template>
                </template>
              </td>
              <template v-if="checkAccess()">
                <td class="text-center" :style="rowColor(item)">
                  <v-chip
                    v-if="item.additional_cause == `GC`"
                    small
                    class="ma-2"
                    color="red accent-4"
                    outlined
                    label
                    :disabled="latestDateForCancellation(item.status, item.imported_status,item.hrd_incharge_name)"
                    @click="cancelOrder(item)"
                  >
                    <v-icon> mdi-cancel </v-icon>
                  </v-chip>
                  <v-chip
                    v-else-if="item.status == 3"
                    small
                    class="ma-2"
                    color="red accent-4"
                    outlined
                    label
                    disabled
                  >
                    <v-icon> mdi-cancel </v-icon>
                  </v-chip>
                  <v-chip
                    v-else
                    small
                    class="ma-2"
                    :color="!item.status ? '#EF6C00' : '#093b03'"
                    outlined
                    label
                    :disabled="latestDateForCancellation(item.status, item.imported_status , item.hrd_incharge_name)"
                  >
                    <v-icon v-if="item.status == 2"> mdi-check-all </v-icon>
                    <v-icon v-else-if="item.status == 3" color="red"> mdi-cancel </v-icon>
                    <v-icon v-else-if="!item.status"> mdi-progress-clock </v-icon>
                  </v-chip>
                  <v-chip
                    small
                    class="ma-2"
                    color="red accent-4"
                    outlined
                    label
                    v-show="showSoftDelete()"
                    @click="softDeleteOrder(item)"
                  ><v-icon>mdi-delete</v-icon>
                  </v-chip>
                </td>
              </template>
            </tr>
        </template>
      </v-data-table>

    <v-overlay :value="loadMore" overlay>
      <div v-if="reasonHrdLoading">
        <v-row class="justify-center">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-row>
        <v-row class="justify-center">
          <span style="color:red"><b>Note: Internet access is required!!</b></span>
        </v-row>
      </div>
      <div v-else>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </div>



    </v-overlay>

    <v-overlay :value="loadMoreEmail" overlay>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        <b><p style="font-size: 15px !important; margin-left: -15px !important;">SENDING EMAIL</p></b>  
    </v-overlay>
    <!-- NOTE: For carousel of showing of images based on selected item -->
    <v-row justify-center v-if="!loggedInUser.fax_number || !loggedInUser.phone">
      <v-dialog
        v-model="haveNumberData"
        persistent
        width="350"
        @keydown.esc="haveNumberData = false;"
      >
        <v-card>
          <v-card-title class="form-card-title">
            <v-icon dark class="mr-1" size="20">mdi-information-variant</v-icon>
            <span class="span-style">Message</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <h5 class="text-center" style="color: #666666;">
                {{ $t('global.msg') }}
              </h5>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="haveNumberData = false;" class="mt-1 mb-1" color="#0C2D48" style="color: white; height: 28px !important; font-size: 11px;" dense>OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    

    <!-- NOTE: For carousel of showing of images based on selected item -->
    <v-dialog v-model="imgDialog"  @keydown.esc="imgDialog = false" width="600" persistent>

      <v-card>
          <v-card-title class="form-card-title">
              <span class="span-style">Situation Photos</span>
              <v-spacer></v-spacer>
              <v-icon color="white" @click="imgDialog = false; modelImg = 0">mdi-close</v-icon>
          </v-card-title>
          <v-card-text style="padding:0px; ">
              <v-carousel
                delimiter-icon="mdi-checkbox-intermediate"
                show-arrows-on-hover
                height="450"
                :cycle="false"
                :continuous="false"
                v-model="modelImg"
              >
                <v-carousel-item 
                  v-for="(item, i) in loadAllImages" 
                  :key="i"
                  >
                    <v-img
                      :src="item.imgUrl"
                      contain
                    >
                    </v-img>
                </v-carousel-item>
              </v-carousel>
          </v-card-text>
      </v-card>
    </v-dialog>

    <!-- NOTE: For checking of data before send to Hacchu Dialog -->
    <v-row justify-center>
      <v-dialog
        v-model="checkingDialog"
        persistent
        width="600"
        @keydown.esc="checkingDialog = false;"
      >
        <v-form
          id="submitData"
          ref="submitData"
          @submit.prevent="submitData(edit)"
          enctype="multipart/form-data"
        >
          <v-card>
            <v-card-title class="form-card-title">
              <span class="span-style">Checking of Data</span>
              <v-spacer></v-spacer>
              <v-icon dark medium @click="checkingDialog = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="mt-2"> 
                  <v-col cols="6">
                    <v-text-field
                      v-model="edit.construction_code"
                      :label="$t('homeHeader.constructionCode')"
                      dense
                      outlined
                      persistent-placeholder
                      autocomplete="off"
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="edit.additional_industry_id"
                      :items="industryData"
                      :item-text="item => `( ${item.additional_industry_id} ) ${item.product_industry_name}`"
                      item-value="additional_industry_id"
                      :label="$t('industryHeader.additionalOrderingIndustry')"
                      dense
                      outlined
                      persistent-placeholder
                      autocomplete="off"
                      readonly
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="edit.industry_product_name"
                      :label="$t('homeHeader.partsProductsName')"
                      dense
                      outlined
                      persistent-placeholder
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="edit.quantity"
                      :label="$t('homeHeader.quantity')"
                      dense
                      outlined
                      persistent-placeholder
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="edit.unit"
                      :label="$t('homeHeader.unit')"
                      dense
                      outlined
                      persistent-placeholder
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="edit.additional_cause"
                      :label="$t('homeHeader.additionalCause')"
                      dense
                      outlined
                      persistent-placeholder
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="edit.additional_reason"
                      :label="$t('homeHeader.additionalReason')"
                      dense
                      outlined
                      persistent-placeholder
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="edit.desired_delivery_address"
                      :items="desiredDeliveryAddress"
                      item-text="text"
                      item-value="id"
                      :label="$t('homeHeader.desiredDeliveryAddress')"
                      dense
                      outlined
                      persistent-placeholder
                      readonly
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="edit.delivery_address"
                      :label="$t('homeHeader.deliveryAddress')"
                      dense
                      outlined
                      persistent-placeholder
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="edit.recipient"
                      :label="$t('homeHeader.recipient')"
                      dense
                      outlined
                      persistent-placeholder
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="edit.recipient_tel_number"
                      :label="$t('homeHeader.recipientTelNumber')"
                      dense
                      outlined
                      persistent-placeholder
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="edit.recipient_address"
                      rows="2"
                      :label="$t('homeHeader.recipientAddress')"
                      dense
                      outlined
                      persistent-placeholder
                      readonly
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="mt-1 mb-1" type="submit" color="#790606" style="color: white; height: 28px !important; font-size: 11px;" dense @click="edit.type = `cancelled`">
                <v-icon dark small class="mr-1">
                  mdi-close-box-outline
                </v-icon>
                Disapprove
              </v-btn>
              <v-btn class="mt-1 mb-1" type="submit" color="#0C2D48" @click="edit.type = `approved`" style="color: white; height: 28px !important; font-size: 11px;" dense>
                <v-icon dark small class="mr-1">
                  mdi-content-save
                </v-icon>
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>


    <v-dialog v-model="statusLegend" width="620">
      <v-card class="text-center">
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-center">STATUS</th>
              <th class="text-center">DETAIL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in statusData" :key="i">
              <td><v-btn hide-details outlined small :color="item.color" :style="{ 'background-color': item.background }">{{ item.name }}</v-btn></td>
              <td>{{ item.detail }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-dialog>

  </v-container>

  
</template>

<script>
  import axios from "axios";
  import moment from 'moment';
  import swal from "sweetalert2";
  import Toolbar from './includes/Toolbar.vue'
  import { mapActions, mapState } from 'vuex';

export default {
  name: "Home",
  components: {
    "tool-bar": Toolbar,
  },
  sockets: {
    updateGcTsuikaData: function(socket) {
      // console.log(socket);
      if(socket.system == 'gc-tsuika' && socket.dispatchType == 'response-local') {

        if(socket.request == 'hrdReason') {
          if(socket.senderInfo == this.loggedInUser.user_id) {      
            axios({
              method: 'post',
              url: `${window.api}/mysql/updateHrdStatus`,
              data: {
                "request": this.tempHrdOrderNo,
              },
              headers: {
                'x-api-key' : process.env.VUE_APP_HEADERS
              }
            }).then(()=>{
              this.sendEmailNotification(this.tempHrdOject)
              this.loadMore = false;
              this.reasonHrdLoading = false;
              this.getTsuikaData();
            })
          }
        }

        if(socket.request == 'cancelOrderToHacchu') {
          if(socket.status == 200) {
            this.updateOrderStatus(socket.data);
          }
        }
      }
    }
},
  data(){
    return {
      reasonHrdLoading:false,
      tempHrdOject:{},
      tempHrdOrderNo:{},
      statusLegend:false,
      statusData:[
        {name:'GC SENT', color:'teal darken-4', background:'white',detail:'GC placed an order but it was not imported into hacchu'},
        {name:'GC CANCELLED', color:'#D32F2F', background:'white',detail:'GC cancelled their order'},
        {name:'PENDING', color:'lime darken-4', background:'white',detail:'GC VIEW - GC placed an order but needs approval for HRD user'},
        {name:'ASSESS', color:'#D32F2F', background:'white',detail:'HRD VIEW - GC placed an order but needs approval for HRD user'},
        {name:'APPROVED', color:'teal darken-4', background:'white',detail:'HRD approved the order placed by GC, but it was not imported into Hacchu'},
        {name:'HRD CANCELLED', color:'#D32F2F', background:'white',detail:'HRD disapproved the order placed by GC'},
        {name:'ORDERED', color:'white', background:'#004D40',detail:'Successfully ordered in Hacchu with an email sent to the supplier'},
        {name:'ORDERED', color:'white', background:'#D50000',detail:'Successfully ordered in Hacchu without sending an email to the supplier'},
      ],
      hideSomeColumn:false,
      items: ['red', 'black', 'green'],
      toolbar: {
        title: 'toolbar.homeTitle',
        sub_title: 'navigation.home',
        back: false,
        hasSearch: true,
      },
      haveNumberData: true,
      editDialog: false,
      tempConstCode: '',
      tempOrderingIndustry: '',
      tempPartProduct: '',
      tempQty: '',
      tempUnit: '',
      tempDesiredDeliveryDate: '',
      tempAdditionalCause: '',
      tempAdditionalReason: '',
      tempDesiredDeliveryAdd: '',
      tempDeliveryAdd: '',
      tempDeliveryAdd2: '',
      categoryData: [],
      editDataItem: {},
      tempRecipient: '',
      tempRecipientTelNum: '',
      tempRecipientAdd: '',
      desiredDeliveryAddress: [
        { id: 1, text: '現場'},
        { id: 2, text: 'GC仙台 ( A ) 倉庫', value: 'N/A'},
        { id: 3, text: 'GC宮城 ( B ) 倉庫', value: '宮城県仙台市宮城野区港4-4-1'},
        { id: 4, text: 'GC群馬 ( D ) 倉庫', value: '群馬県伊勢崎市西野町350'},
        { id: 5, text: 'GCタカノ ( F ) 倉庫', value: '富山県射水市流通センター水戸田3-6　城西物流倉庫㈱'},
        { id: 6, text: 'GC岡山 ( I ) 倉庫', value: '岡山県倉敷市東塚7丁目6-16'},
        { id: 7, text: 'GC広島 ( J ) 倉庫', value: '広島県神石郡神石高原町階見1239-1'},
        { id: 8, text: 'GC山陰 ( K ) 倉庫', value: '鳥取県境港市昭和町　無番地'},
        { id: 9, text: 'その他'},
      ],
      modelImg: 0,
      imgDialog: false,
      loadAllImages: [],
      tableHeight: window.innerHeight - 210,
      checkingDialog: false,
      edit: {},
      desireDeliveryItem: [
        { id: '1', text: '現場' },
        { id: '2', text: 'ＧＣ倉庫' },
        { id: '3', text: 'Other' },
      ],
      loadMore: false,
      loadMoreEmail: false,
      isSearchStatus: false,
      gcGroup: ["GC Leader", "GC Member", "Nihon Sangyou"],
    }
  },
  computed: {
    ...mapState(['translation', 'search', 'tsuikaData', 'industryData', 'tsuikaUserData', 'emailData', 'accessViewData', 'accessRightData','translation']),
    searchTsuika(){
      if(this.search){
        return this.displayTsuikaData.filter((item)=>{
          return item.additional_industry_id.includes(this.search) ||
          item.construction_code.includes(this.search)
        })
      }else{
        return this.displayTsuikaData
      }
    },
    
    displayTsuikaData() {

        var user = this.loggedInUser, data = this.tsuikaData, email = this.emailData, mergeArray = [];
        email

        if(this.tsuikaUserData.length) {
          data = data.filter(r => {
                  if(r.status == 1) {
                    r.statusName = "gc"
                  } else if(r.status == 2) {
                    r.statusName = 'hrd'
                  } else if(r.status == 3) {
                    r.statusName = 'cancel'
                  } else if(!r.status) {
                    r.statusName = 'pending';
                  }
                  return r;
                }).sort((a, b) => b.id - a.id);
          
          
          if(user.account_type == 'GC Leader' || user.account_type == 'GC Member') {
            mergeArray = data.filter(res => res.user_company_name == user.sub_group);
          } 
          else {
            var hasAccess = this.accessViewData.find(r => r.user_id == user.user_id);
            var haveCreated = data.filter(res => res.created_by == user.user_id)
            var findAccess = email.filter(res => res.user_id == user.user_id && res.email == user.email)

            if(!hasAccess) {
              if(haveCreated.length && !findAccess.length) {
                mergeArray = haveCreated.sort((a, b) => b.id - a.id);
              }

              if(haveCreated.length && findAccess.length) {
                let categories = findAccess.map(res => res.additional_industry_id);
                let allData = data.filter(res => categories.includes(res.additional_industry_id)).concat(haveCreated)
                
                mergeArray = allData.filter((res, i, arr) => arr.findIndex(r => (r.id === res.id)) === i).sort((a, b) => b.id - a.id); //removing of duplicates if sender and also incharge
              }

              if(findAccess.length && !haveCreated.length) {
                let categories = findAccess.map(res => res.additional_industry_id);
                  mergeArray = data.filter(res => categories.includes(res.additional_industry_id)).sort((a, b) => b.id - a.id);
                }
              } else {
                if(hasAccess.departments.includes(',')) {
                  let departments = hasAccess.departments.split(',');
                  mergeArray = data.filter(r => departments.includes(r.department));
                } else {
                  mergeArray = data.filter(r => r.department == hasAccess.departments);
                }
              }

              if(this.search) {
                  mergeArray = mergeArray.filter((res) => res.tempId = res.order_number).sort((a, b) => b.id - a.id);
              }
              mergeArray = mergeArray.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i) //removing of duplicates by id (unique property)

              if(findAccess.length) {
                let rem = [...new Set(findAccess.map(m => m.department))]; //removing of duplicates
                mergeArray = mergeArray.filter(r => rem.includes(r.department)).filter(rec => {
                  if(!rec.status) {
                    rec.statusName = "assess";
                  }
                  return rec;
                });
              }
          }
          // console.log(mergeArray);
          return mergeArray;
        } else {
          return [];
        }
    },
    footerProps() {
      let userData = this.$store.state.loggedInUser
      let findFilteredData = this.tsuikaData.filter(res => res.user_email == userData.email || res.assigned_to == userData.user_id);
      
      const len = findFilteredData.length / 50;
      let arr = [];
      let allData = [-1];
      var foorterNum = 0;
      if(len % 0 != 0) {
          foorterNum = Math.trunc(len) + 1;
      }
      for(let i = 1; i <= foorterNum; i++) {
          arr.push(i * 50)
      }

      if(arr.length == 1) {
          arr.push(100)
      } 

      return {'items-per-page-options': arr.concat(allData)};
    },
    productHeaderData() {
      if(!this.hideSomeColumn){
        let headers = [
                { 
                    id: 1,
                    text: this.$t("homeHeader.requestDate"),
                    align: 'center',
                    class: 'white--text title',
                },
                {
                    id: 2,
                    text: this.$t("homeHeader.orderNo"),
                    align: 'center',
                    value: 'order_number',
                    class: 'white--text title'
                },
                {
                    id: 3,
                    text: this.$t("homeHeader.constructionCode"),
                    align: 'center',
                    value: 'construction_code',
                    class: 'white--text title'
                },
                {
                    id: 4,
                    text: this.$t("homeHeader.requesterName"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 21,
                    text: this.$t('homeHeader.requesterEmail'),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 5,
                    text: this.$t("homeHeader.categoryId"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 6,
                    text: this.$t("homeHeader.categoryName"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 7,
                    text: this.$t("homeHeader.partsProductsName"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 8,
                    text: this.$t("homeHeader.quantity"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 9,
                    text: this.$t("homeHeader.unit"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 10,
                    text: this.$t("homeHeader.desiredDeliveryDate"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 11,
                    text: this.$t("homeHeader.additionalCause"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 12,
                    text: this.$t("homeHeader.additionalReason"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 14,
                    text: this.$t("homeHeader.situationPhoto"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 15,
                    text: this.$t("homeHeader.desiredDeliveryAddress"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 16,
                    text: this.$t("homeHeader.deliveryAddress"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 17,
                    text: this.$t("homeHeader.recipient"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 18,
                    text: this.$t("homeHeader.recipientTelNumber"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 19,
                    text: this.$t("homeHeader.recipientAddress"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 20,
                    text: this.$t("homeHeader.status"),
                    align: 'center',
                    value: 'statusName',
                    class: 'white--text title'
                },
                {
                    id: 22,
                    text: this.$t("homeHeader.actions"),
                    align: 'center',
                    class: 'white--text title'
                },
                
            ];
      let user = this.loggedInUser;
      // return (user.access.length == 11 || this.gcGroup.includes(user.account_type)) ? headers : headers.slice(0, -1)
      return (user.access.length == 12 || this.gcGroup.includes(user.account_type)) ? headers : headers.slice(0, -1)// REVIEW Display for order history
      
    }else{
        let headers = [
                { 
                    id: 1,
                    text: this.$t("homeHeader.requestDate"),
                    align: 'center',
                    class: 'white--text title',
                },
                {
                    id: 2,
                    text: this.$t("homeHeader.orderNo"),
                    align: 'center',
                    value: 'order_number',
                    class: 'white--text title'
                },
                {
                    id: 3,
                    text: this.$t("homeHeader.constructionCode"),
                    align: 'center',
                    value: 'construction_code',
                    class: 'white--text title'
                },
                {
                    id: 4,
                    text: this.$t("homeHeader.requesterName"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 21,
                    text: this.$t('homeHeader.requesterEmail'),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 5,
                    text: this.$t("homeHeader.categoryId"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 6,
                    text: this.$t("homeHeader.categoryName"),
                    align: 'center',
                    class: 'white--text title'
                },
                {
                    id: 20,
                    text: this.$t("homeHeader.status"),
                    align: 'center',
                    value: 'statusName',
                    class: 'white--text title'
                },
                {
                    id: 22,
                    text: this.$t("homeHeader.actions"),
                    align: 'center',
                    class: 'white--text title'
                },
                
            ];
      let user = this.loggedInUser;
      // return (user.access.length == 11 || this.gcGroup.includes(user.account_type)) ? headers : headers.slice(0, -1)
      return (user.access.length == 12 || this.gcGroup.includes(user.account_type)) ? headers : headers.slice(0, -1)// REVIEW Display for order history
      }

    }
  },
  methods: {
    ...mapActions(['getTsuikaData', 'loadCategoryData', 'getEmail', 'getTsuikaUsers', 'getAccessView']),
    manualImport(item){
      // console.log(item,'itemmm');
      let user = this.loggedInUser;
      if(user.user_name == 'Arnie Claudio') {
        swal.fire({
          title: "You want to set the status as ORDERED",
          text: `${item.construction_code}-(${item.additional_industry_id})-[${item.order_number}]?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `Confirm`,
          denyButtonText: `Cancel`,
          allowOutsideClick: false,
          width:320,
        }).then(async(result)=>{
          if(result.isConfirmed){
            // console.log('send');
            await axios({
              method: 'POST',
              url: `${window.api}/mysql/manualImport/order`,
              data: item,
              headers: {
                  'x-api-key' : process.env.VUE_APP_HEADERS
              }
            }).then(() => {
                swal.fire({
                    position: "center",
                    icon: "success",
                    title: `Successfully change Status Order No. ${item.order_number}`,
                    showConfirmButton: false,
                    timer: 1500,
              });
              this.getTsuikaData().then(() => {
                this.getAccessView().then(() => {
                  this.loadCategoryData().then(() => {
                    this.getEmail();
                    this.getTsuikaUsers();
                  })
                })
              })
            });
          }
        })
      } 
    },
    showLegend(){
      this.statusLegend = true
      // alert("Test")
    }, 
    handleIconClick() {
        // console.log('Icon clicked');
        this.hideSomeColumn = !this.hideSomeColumn;
    },

    isEmailSend(item){
      if (item.is_email_sent == '1') {
        return 'teal darken-4';
      } else {
        return 'red accent-4';
      }
    },
    checkAccess(){
      let user = this.loggedInUser;
      const fullAdmin = 12;//REVIEW Display for order history
      // const fullAdmin = 11;
      if(user.access.length == fullAdmin || this.gcGroup.includes(user.account_type)) {
        return true;
      } else {
        return false;
      }
    },
    showSoftDelete(){
      let user = this.loggedInUser;
      if(user.user_name == 'Arnie Claudio') {
        return true;
      } else {
        return false;
      }
    },
    latestDateForCancellation(status,imported,incharge){      
      if(status == 4) {
        return true;
      } 
      if(imported == 3){
        return true;
      }
      if(!incharge){
        return true;
      }
    },
    headerWidth(id){
      if(id == 1) {
        return 'min-width: 95px !important;'
      } else if(id == 3) {
        return 'min-width: 122px !important;'
      } else if(id == 4) {
        return 'min-width: 130px !important;'
      } else if(id == 6) {
        return 'min-width: 140px !important;'
      } else if(id == 21) {
        return 'min-width: 160px !important;'
      }
    },
    getFilteredData(e){
      let tempArr = [];
      let test = 1;
      e.forEach(r => {
        if(tempArr.findIndex(rec => rec.orderNumber == r.order_number) === -1){
          tempArr.push({
            tempId: test++,
            orderNumber: r.order_number
          });
        }
      });

      if(tempArr.length) {
        e.forEach(r => {
          tempArr.filter(rec => {
            if(r.order_number == rec.orderNumber) {
              r.tempoId = rec.tempId;
            }
          })
        })
      }
    },
    rowColor(data) {
      let num = data.tempoId ? Number(data['tempoId'].toString().slice(-1)) : data.order_number
      if(num % 2 == 0) {
        return 'background-color: #e1e1e1';
      } else {
        return 'background-color: #fff';
      }
    },
    withSituationPhoto(arrStr){
      return JSON.parse("[" + arrStr + "]")[0]?.length;
    },
    convertDate(timeStamp) {
      return  moment(timeStamp).format('YYYY-MM-DD')
    },
    getRequester(type, requesterId){
      const findRequesterName = this.tsuikaUserData.find(rec => rec.UserId == requesterId) //for actual

      if(findRequesterName) {
        if(type == 'name') {
          return findRequesterName.Name.toUpperCase();
        } else {
          return findRequesterName.MailAddress;
        }
      } else {
        return '';
      }

    },
    checkItemData(item){
      // this.tempHrdOrderNo = item.order_number
      item.desired_delivery_address = Number(item.desired_delivery_address)
      this.edit = item;
      this.checkingDialog = true;
    },
    async dispatchUpdatedData() {
      this.$socket.emit('gcTsuikaData', {
        system: 'gc-tsuika',
        request: 'updateTsuikaData',
        dispatchType: 'request-local',
        port: this.windowLoc.port,
        protocol: this.windowLoc.protocol,
        hostname: this.windowLoc.hostname,
      })
    },

    async updateStatus(editData, isLastItem) {
      this.loadMore = true;
      this.tempHrdOrderNo = editData
      await axios({
        method: 'post',
        url: `${window.api}/mysql/updateStatus`,
        data: {
          "request": this.tempHrdOrderNo,
          "updated_by": this.loggedInUser.user_id,
          "isLastItem": isLastItem
        },
        headers: {
          'x-api-key' : process.env.VUE_APP_HEADERS
        }
      }).then(res => {
        if(res.data) {
          this.checkingDialog = false;
          if(res.data != 'updated') {
            var email_to = this.tsuikaUserData.find(r => res.data.data.map(rec => rec.created_by).includes(r.UserId))?.MailAddress;
            var userName = this.loggedInUser.user_name
            var userEmail = this.loggedInUser.email
          }

          if(res.data.isCancellation) {
            swal.fire({
              position: "center",
              icon: "success",
              title: `Data is CANCELLED`,
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              let data = res.data.data;
              let construction_code = data.map(rec => rec.construction_code)[0];
              let additional_industry_id = data.map(rec => rec.additional_industry_id)[0];
              let getCategoryCode = [...new Set(data.map(r => r.additional_industry_id))].toString();
              let categoryData = this.industryData.find(r => r.additional_industry_id == getCategoryCode);
              let categoryName = categoryData.additional_industry_id + " " + categoryData.product_industry_name;
              // let categoryAssignEmail = this.$store.state.categoryEmails.find(r => r.id == getCategoryCode)?.emailAddress;
              let categoryAssignEmail = this.emailData.filter(r => r.additional_industry_id == getCategoryCode && r.is_email_reciever =='1').map(r => r.email);
              
              let obj = {
                construction_code: construction_code,
                additional_industry_id: additional_industry_id,
                senderEmail: userEmail,
                to: email_to,
                status: "全ての注文はキャンセルされました。[HRD起因]", //ALL ORDERS ARE CANCELLED [HRD REASON]
                name_from: userName,
                categoryName: categoryName,
                senderUser: categoryAssignEmail,
              }

              this.sendEmailNotification(obj).then(() => { //comment now
                this.$socket.emit('gcTsuikaData', {
                  system: 'gc-tsuika',
                  request: 'hrdCancellation',
                  dispatchType: 'request-local',
                  port: this.windowLoc.port,
                  protocol: this.windowLoc.protocol,
                  hostname: this.windowLoc.hostname,
                })
              })                                          //comment now          

            });
          } else if(res.data == 'updated') {
            swal.fire({
              position: "center",
              icon: "success",
              title: `Successfully saved data`,
              showConfirmButton: false,
              timer: 1500,
            });
          } else if(res.data.isSaveToHacchu){
            this.loadMore = true;
            this.reasonHrdLoading = true;
            let senderInfo = this.loggedInUser.user_id;
            let categoryData = this.industryData.find(r => r.additional_industry_id == res.data.industryId);
            let categoryName = categoryData.additional_industry_id + " " + categoryData.product_industry_name;
            // let categoryAssignEmail = this.$store.state.categoryEmails.find(r => r.id == res.data.industryId)?.emailAddress;
            let categoryAssignEmail = this.emailData.filter(r => r.additional_industry_id == res.data.industryId && r.is_email_reciever =='1').map(r => r.email);
                // console.log(categoryAssignEmail,'categoryAssignEmail');

            this.tempHrdOject = {
              construction_code: res.data.constCode,
              additional_industry_id: res.data.industryId,
              senderEmail: userEmail,
              to: email_to,
              status: "全ての注文は承認されました。[HRD起因]", //ALL ORDER/S ARE APPROVED [HRD REASON]
              name_from: userName,
              categoryName: categoryName,
              senderUser: categoryAssignEmail,
            }


            // this.sendEmailNotification(obj).then(() => { //comment now
              this.$socket.emit('gcTsuikaData', {
                system: 'gc-tsuika',
                request: 'hrdReason',
                requestData: {
                  data: res.data.data,
                  constCode: res.data.constCode,
                  industryId: res.data.industryId,
                  senderInfo: senderInfo
                },
                dispatchType: 'request-local',
                port: this.windowLoc.port,
                protocol: this.windowLoc.protocol,
                hostname: this.windowLoc.hostname
              })
            // })                                         //comment now            
          }
        }
      })
    },
    async sendEmailToGcPlanner(data){
      let toSendData = Object.assign({}, data);

      toSendData['senderStatus'] = 'Gc Planner';
      
      //REVIEW Change when Deploy
      const url = 'https://ihs.hrd-s.com/hrdinquiry_email/api/mail/gctsuika/store' //ACTUAL
      // const url = 'http://127.0.0.1:8000/api/mail/gctsuika/store' //DEVELOPER - Testbucket
      
      return await axios({
        method: 'post',
        url: url,
        data: toSendData
      }).then(res => {
        if(res.data == 'Done!') {
          return 'success';
        } 
      }).catch(err => {
        if(err) {
          return 'failed';
        }
      })

      
    },
    successEmailNotification(){
      this.loadMoreEmail = false;
      swal.fire({
        icon: 'success',
        title: 'Email Sent',
        showConfirmButton: false,
        timer: 2000
      })
    },
    failedNotification() {
      this.loadMoreEmail = false;
      swal.fire({
          title: "Email Sending Error",
          text: "There's an error in Email Sending. Please send email manually. Thank you.",
          icon: "error",
      });
    },
    //REVIEW Change when Deploy
    emailContent(item) {
      let toSend = [item.to];
      let ccReciever = item.senderUser
      let ccRecieverAddition = ['iconn@hrd-s.com', 'le-garcia@hrd-s.com', 'a-maeda@hrd-s.com', 'm-nakashima@hrd-s.com'];
      let toCc = ccRecieverAddition.concat(ccReciever)

      return {
        construction_code: item.construction_code,
        // from: '[TEST]: Gc Tsuika Hacchu System', //DEVELOPER - Testbucket
        from: 'Gc Tsuika Hacchu System', //ACTUAL
        senderEmail: 'no-reply@hrd-ihs.com', 
        to: toSend,
        cc: toCc,
        // subject: '[TEST]: Gc Tsuika Hacchu System', //DEVELOPER - Testbucket
        subject: 'Gc Tsuika Hacchu System', //ACTUAL
        attachment: [],
        remarks: "",
        status: item.status,
        name: item.name_from,
        senderUser: item.senderUser.length==1 ? item.senderUser[0] : item.senderUser[1],
        categoryName: item.categoryName,
      }
    },
    async sendEmailNotification(item) {
      this.loadMoreEmail = true;
      let data = this.emailContent(item);

        if(item.status == '全ての注文はキャンセルされました。[HRD起因]') { //ALL ORDERS ARE CANCELLED [HRD REASON]
          let gcEmailResponse = await this.sendEmailToGcPlanner(data);

          if(gcEmailResponse == 'success') {
              this.successEmailNotification();
          } else {
              this.failedNotification();
          }
          
        } else {
          let gcEmailResponse = await this.sendEmailToGcPlanner(data);

          if(gcEmailResponse == 'success') {
              this.successEmailNotification();
          } else {
              this.failedNotification();
          }
        }
    },
    submitData(data){
      // console.log(data,'data');
      const findSenderData = this.tsuikaUserData.find(rec => rec.UserId == data.created_by);

      const orderNumber = this.edit.order_number;
      let orderData = this.tsuikaData.filter(order => order.order_number == orderNumber)
      orderData = orderData.filter((r, i, a) => a.findIndex(r2 => (r2.id === r.id)) === i);
      
      if(data.type == 'approved') {
        if(orderNumber) {
          if(orderData.length > 1) { //more than 1 items 
            const orderLength = orderData.length - 1;
            const isAlmostApproved = orderData.filter(order => order.status == 2).length; //check if only 1 data is not yet approve

            if(orderLength == isAlmostApproved) {
              swal
                .fire({
                  title: "Confirmation",
                  text: `Are you sure to send this data?`,
                  showDenyButton: true,
                  showCancelButton: false,
                  confirmButtonText: `Confirm`,
                  denyButtonText: `Cancel`,
                }).then(result => {
                  if(result.isConfirmed) {
                    if(!findSenderData){
                      swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Can't send saved to Hacchu. Please register ID: ${data.created_by} first.`,
                        showConfirmButton: false,
                        timer: 2000,
                      })
                    } else {
                      const isLastItem = true;
                      this.updateStatus(this.edit, isLastItem);
                    }
                    
                  }
                })
            } else {
              swal
                .fire({
                  title: "Confirmation",
                  text: `Are you sure to save this data?`,
                  showDenyButton: true,
                  showCancelButton: false,
                  confirmButtonText: `Confirm`,
                  denyButtonText: `Cancel`,
                }).then(result => {
                  if(result.isConfirmed) {
                    const isLastItem = false;
                    this.updateStatus(this.edit, isLastItem).then(() => {
                        this.$socket.emit('gcTsuikaData', {
                        system: 'gc-tsuika',
                        request: 'updateTsuikaData',
                        dispatchType: 'request-local',               
                        port: this.windowLoc.port,
                        protocol: this.windowLoc.protocol,
                        hostname: this.windowLoc.hostname,
                      })
                    });
                  }
                })
            }


          } else {
            swal
              .fire({
                title: "Confirmation",
                text: `Are you sure to send this data?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Confirm`,
                denyButtonText: `Cancel`,
              }).then(result => {
                if(result.isConfirmed) {
                  if(!findSenderData) {
                    swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Can't send saved to Hacchu. Please register ID: ${data.created_by} first.`,
                        showConfirmButton: false,
                        timer: 2000,
                      })
                  } else {
                    const isLastItem = true;
                    this.updateStatus(this.edit, isLastItem);
                  }
                  
                }
              })
          }
        } 
      } else {
        swal
          .fire({
            title: "CANCELLATION",
            text: `If you cancel this data, all request for the same order will be cancelled.`,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: `Confirm`,
            denyButtonText: `Cancel`,
          }).then(result => {
            if(result.isConfirmed) {
              this.updateStatus(this.edit);
            }
          })
      }
    },
    getCategoryData(id) {
      let obj = { additional_industry_id: id }

      Promise.all([
        axios.post(`${window.api}/hrdmssql/checkCategory`, obj),
        axios.post(`${window.api}/hrdmssql/getCategoryData`, obj)
      ]).then(async ([orderIndustry, categories]) => {
        const industry = await orderIndustry.data[0];
        const cat = await categories.data;

        this.tempOrderingIndustry = industry.HATCHU_GYOSYU_MEI;
        this.categoryData = cat.filter(active => active.isActive);
      })
      .catch(err => {
        console.log(err)
      })
    },
    backToForm() {
      this.$store.commit('isFromGc', false);
      this.$router.push("/gc_form");
    },
    async getImages(item) {
      let imgURLGetters = []
      for(let data of JSON.parse(item.situation_photo)){
        const imgUrl =await this.getFileUrl(item.construction_code, data)
        imgURLGetters.push({
          path:data,
          imgUrl:imgUrl
        })
      }

      this.loadAllImages = imgURLGetters
      this.imgDialog = true;
    },
    cancelOrderToHacchu(orderDetail) {
      this.$socket.emit('gcTsuikaData', {
        system: 'gc-tsuika',
        request: 'cancelOrderDetails',
        requestData: orderDetail,
        dispatchType: 'request-local',
        port: this.windowLoc.port,
        protocol: this.windowLoc.protocol,
        hostname: this.windowLoc.hostname,
      })
    },
    cancelOrder(orderDetail){
      // console.log(orderDetail,'orderDetail');
      if (this.translation == 'en'){
        swal
        .fire({
        title: "Confirmation",
        text: `Are you sure to cancel ${orderDetail.construction_code} ( ${orderDetail.additional_industry_id} - ${orderDetail.order_number} ) record?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Confirm`,
        denyButtonText: `Cancel`,
      }).then(res => {
        if(res.isConfirmed) {
          this.loadMore = true;
          const detailsToCancel = {
            construction_code: orderDetail.construction_code,
            caregory_code: orderDetail.additional_industry_id,
            order_number: orderDetail.order_number,
            cancel_by: this.$store.state.loggedInUser.user_name,
          }
          this.cancelOrderToHacchu(detailsToCancel);
        }
      })

      }else{
        swal
        .fire({
        title: "確認",
        text: `${orderDetail.construction_code} ( ${orderDetail.additional_industry_id} - ${orderDetail.order_number} ) の依頼をキャンセルしますか？`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `はい`,
        denyButtonText: `いいえ`,
      }).then(res => {
        if(res.isConfirmed) {
          this.loadMore = true;
          const detailsToCancel = {
            construction_code: orderDetail.construction_code,
            caregory_code: orderDetail.additional_industry_id,
            order_number: orderDetail.order_number,
            cancel_by: this.$store.state.loggedInUser.user_name
          }

          this.cancelOrderToHacchu(detailsToCancel);
        }
      })

      }

    },
    updateOrderStatus(data) {
      // console.log(data,'data');
      axios({
        method: 'post',
        url: `${window.api}/mysql/updateOrderStatus`,
        data: data,
        headers: {
          'x-api-key' : process.env.VUE_APP_HEADERS
        }
      }).then(res => {
        if(res.status == 200) {
          swal.fire({
            position: "center",
            icon: "success",
            title: `Successfully Cancel: ${data.construction_code}`,
            showConfirmButton: false,
            timer: 3000,
          })
          this.loadMore = false;
          this.getTsuikaData();
        }
      })
    },
    softDeleteOrder(item){
      swal.fire({
        title: "You want to delete",
        text: `${item.construction_code}-(${item.additional_industry_id})-[${item.order_number}]?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Confirm`,
        denyButtonText: `Cancel`,
        allowOutsideClick: false,
        width:320,
      }).then(async(result)=>{
        if(result.isConfirmed){
          // console.log('delete');
          await axios({
            method: 'POST',
            url: `${window.api}/mysql/softdelete/order`,
            data: item,
            headers: {
                'x-api-key' : process.env.VUE_APP_HEADERS
            }
          }).then(() => {
              swal.fire({
                  position: "center",
                  icon: "success",
                  title: `Successfully Deleted Order No. ${item.order_number}`,
                  showConfirmButton: false,
                  timer: 1500,
            });
            this.getTsuikaData().then(() => {
              this.getAccessView().then(() => {
                this.loadCategoryData().then(() => {
                  this.getEmail();
                  this.getTsuikaUsers();
                })
              })
            })

        });
        }
      })
    },

    emailSupplier(item){
      let user = this.loggedInUser;
      if(user.user_name == 'Arnie Claudio' && !item.is_email_sent) {
        swal.fire({
          title: "Send email to the supplier?",
          text: `${item.construction_code}-(${item.additional_industry_id})-[${item.order_number}]?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `Confirm`,
          denyButtonText: `Cancel`,
          allowOutsideClick: false,
          width:450,
        }).then(async(result)=>{
          if(result.isConfirmed){
            let supplierTo = !item.email_to_supplier ? [ ] : item.email_to_supplier.split(',')            
            let supplierCc = !item.email_cc_supplier ? [ ] : item.email_cc_supplier.split(',')            
            let hrdInchargeEmail = item.hrd_incharge_email
            let GcRequesterEmail = item.user_email
            let ccData = ['iconn@hrd-s.com', 'le-garcia@hrd-s.com', 'a-maeda@hrd-s.com', 'm-nakashima@hrd-s.com']

            let planCodeCC = []
            if (item.construction_code.startsWith('A')){
              planCodeCC = ['plan@ichijo-sendai.com']
            }else if (item.construction_code.startsWith('B')){
              planCodeCC = ['n-kamada@ichijo-miyagi.co.jp']
            }else if (item.construction_code.startsWith('C')){
              planCodeCC =['y-katou@ichijo-c.com']
            }else if (item.construction_code.startsWith('D')){
              planCodeCC =['d-suzuki@ichijo-gunma.com']
            }else if (item.construction_code.startsWith('F')){
              planCodeCC =['tymplis@takanohome.jp','takanopcf8@takanohome.jp']
            }else if (item.construction_code.startsWith('I')){
              planCodeCC =['ys-yamas@shimoden.co.jp']
            }else if (item.construction_code.startsWith('J')){
              planCodeCC =['precut@yanamoto.co.jp']
            }else if (item.construction_code.startsWith('K')){
              planCodeCC =['hattyu@ichijo-sanin.co.jp']
            }else{
              planCodeCC=[]
            }

            let allCC = supplierCc.concat(hrdInchargeEmail, planCodeCC, GcRequesterEmail,ccData);
            let categoryName = item.additional_industry_id + " " + item.product_industry_name;
            let content = {
              construction_code: item.construction_code,
              from: 'Gc Tsuika Hacchu System',
              senderEmail: 'no-reply@hrd-ihs.com',
              subject: `[SUPPLIER]:${item.construction_code}(${item.additional_industry_id})-Gc Tsuika Hacchu System`,
              attachment: [],
              remarks: "",
              name: item.hrd_incharge_name,
              categoryName: categoryName,
              senderUser: item.hrd_incharge_email,
              to: supplierTo,
              senderStatus: 'Supplier',
              cc: allCC,
              status: item.status == 1 ? '注文完了[GC起因]' : '注文完了[HRD起因]',
            };
      //REVIEW Change when Deploy
            axios.post(`https://ihs.hrd-s.com/hrdinquiry_email/api/mail/gctsuika/store`,content).then((res)=>{ //ACTUAL
            // axios.post(`http://127.0.0.1:8000/api/mail/gctsuika/store`,content).then((res)=>{
              if(res.data == 'Done!') {
                swal.fire({
                  width:300,
                  icon: 'success',
                  title: 'Email Sent',
                  showConfirmButton: false,
                  timer: 2000
                })
            axios({
              method: 'POST',
              url: `${window.api}/mysql/manualEmail/order`,
              data: item,
              headers: {
                  'x-api-key' : process.env.VUE_APP_HEADERS
              }
            }).then(() => {
              swal.fire({
                position: "center",
                icon: "success",
                title: `Successfully change Status Order No. ${item.order_number}`,
                showConfirmButton: false,
                timer: 1500,
              });
              this.getTsuikaData().then(() => {
                this.getAccessView().then(() => {
                  this.loadCategoryData().then(() => {
                    this.getEmail();
                    this.getTsuikaUsers();
                  })
                })
              })
            });
          }else{
            swal.fire({
              icon: 'error',
              title: 'Email Not Sent',
              showConfirmButton: true,
            })
          }
        })
          }
        })
      }
    }
  },//end method
  watch: {
    loadMore(val) {
      if(!val) {
        // this.sendEmailNotification(this.prevConstructionCode);
      }
    },
    tsuikaUserData(val) {
      if(val.length) {
        // this.loadMore = false;
      } 
    },
    search(val) {
      if(val == 'gc') {
        this.isSearchStatus = true;
      }
    },
    displayTsuikaData(val) {
      if(val) {
        // this.loadMore = false;
        if(this.tsuikaUserData.length) {
          this.loadMore = false;
        }
      } else {
        this.loadMore = false;
      }
    }
  },
  
  mounted() {},
  created() {
      this.$store.state.search = '';
      this.loadMore = true;
      
      this.getTsuikaData().then(() => {
        this.getAccessView().then(() => {
          this.loadCategoryData().then(() => {
            this.getEmail();
            this.getTsuikaUsers();
          })
        })
      })
  },
};
</script>

<style scoped>

.table-header {
  background: #274761 !important;
  color: white !important;
}

.v-data-table /deep/ .sticky {
  left: -1px !important;
  z-index: 5 !important;
  background-color: #274761 !important;
  box-shadow:inset -1px 0 0  white !important;
  color: white !important;
}

.v-data-table /deep/ .stickyCode {
  left: 86px !important;
  z-index: 5 !important;
  box-shadow:inset -1px 0 0  white !important;
  color: white !important;
  background-color: #274761 !important;
}

.stickyTd{
    z-index: 3 !important; 
    position: sticky;
    left: 0px !important;
}
.stickyTdCode{
    z-index: 3 !important; 
    position: sticky;
    left: 86px !important;
}

.text {
  border-right: 2px solid white !important;
  background-color: #274761 !important;
  color: white !important;
  text-align: center !important;
}

.v-data-table .highlight:hover td {
  background-color: gray !important;
}
</style>
